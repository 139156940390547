import React, { useState, useEffect } from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import TimeAgo from "react-timeago";
import ReactMapGL, { Source, Layer, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import _ from "lodash";

import "./App.css";
import { AISContract } from "./contract";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";

import brand from "./brand.png";

const accentColor = "rgb(36, 140, 139)";
const border = `solid 1px ${accentColor}`;
const consumer = "0x998e88545De7D82743fBf7D0bF299DbF4dfb28CE";

const injected = new InjectedConnector({ supportedChainIds: [5] });

function App() {
  const [imo, setIMO] = useState();
  const [contract, setContract] = useState();
  const [requests, setRequests] = useState([]);
  const [positions, setPositions] = useState([]);
  const [geojson, setGeoJSON] = useState();
  const { account, library, activate, error } = useWeb3React();
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const isUnsupportedChainIdError = error instanceof UnsupportedChainIdError;

  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 0,
  });

  useEffect(() => {
    activate(injected);
  }, []);

  useEffect(() => {
    if (library) {
      setContract(new library.eth.Contract(AISContract.abi, consumer));
    }
  }, [library]);

  useEffect(() => {
    if (contract) {
      contract.events
        .Request({ fromBlock: 0, toBlock: "latest" })
        .on("data", (event) => {
          setRequests((requests) =>
            _.uniqBy([...requests, event], "transactionHash")
          );
        });
      contract.events
        .Position({ fromBlock: 0, toBlock: "latest" })
        .on("data", (event) => {
          setPositions((positions) =>
            _.uniqBy([...positions, event], "transactionHash")
          );
        });
    }
  }, [contract]);

  useEffect(() => {
    if (requestSubmitted) {
      setViewport((viewport) => ({
        ...viewport,
        zoom: 10,
        latitude: positions.length
          ? positions[positions.length - 1].returnValues.lat / 1000000
          : 0,
        longitude: positions.length
          ? positions[positions.length - 1].returnValues.lon / 1000000
          : 0,
      }));
    }
  }, [positions]);

  const submit = (e) => {
    e.preventDefault();
    contract?.methods.requestPosition(imo).send({
      from: account,
      gas: 3000000,
    });
    setRequestSubmitted(true);
  };

  useEffect(() => {
    if (positions.length) {
      const features = {};
      positions.forEach((position) => {
        features[position.returnValues.imo] = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              position.returnValues.lon / 1000000,
              position.returnValues.lat / 1000000,
            ],
          },
          properties: {
            name: position.returnValues.imo,
            title: position.returnValues.imo,
          },
        };
      });
      setGeoJSON({
        type: "FeatureCollection",
        features: Object.entries(features).map(([imo, feature]) => {
          return feature;
        }),
      });
    }
  }, [positions]);

  const layerStyle = {
    id: "path",
    type: "circle",
    paint: {
      "circle-radius": 7,
      "circle-color": "#00dbac",
    },
  };

  return (
    <>
      {isUnsupportedChainIdError && (
        <div style={{ textAlign: "center", color: "red", fontSize: "40px" }}>
          Wrong Ethereum chain! Please switch to the Goerli testnet!
        </div>
      )}
      <div style={{ display: "flex", height: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 5,
            height: "100%",
          }}
        >
          <div>
            <div
              style={{
                borderBottom: border,
                padding: "4px 0",
              }}
            >
              <img src={brand} height={40} alt="Zuma Labs"></img>
            </div>
            <div style={{ padding: "4px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Label>AIS Request</Label>
                <form
                  onSubmit={submit}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <input
                    style={{
                      flex: 1,
                      padding: "4px",
                      marginRight: "4px",
                      borderRadius: "4px",
                      borderTopLeftRadius: "0",
                      height: "30px",
                      backgroundColor: "rgba(0,0,0,0.3)",
                      border,
                      fontSize: "1em",
                      color: "white",
                    }}
                    placeholder={"Vessel IMO (max 1 request per hour)"}
                    type="text"
                    onChange={(e) => setIMO(e.target.value)}
                  />
                  <button type="submit">Get Position</button>
                </form>
              </div>
            </div>
          </div>
          <div style={{ flex: 1, padding: "4px" }}>
            <ReactMapGL
              {...viewport}
              width="100%"
              height="100%"
              onViewportChange={(viewport) => setViewport(viewport)}
              mapboxApiAccessToken="pk.eyJ1IjoienVtYWNoYXNlIiwiYSI6ImNrdmRuZnIzZzQzeWQzMWxwc3cxZmdkZWIifQ.rRsUp7Ao8GUkWKqIBHEpug"
              mapStyle="mapbox://styles/mapbox/dark-v9"
            >
              {geojson !== undefined && (
                <>
                  <Source id="my-data" type="geojson" data={geojson}>
                    <Layer {...layerStyle} />
                  </Source>
                  {geojson.features.map((feature) => (
                    <Popup
                      key={feature.properties.name}
                      latitude={feature.geometry.coordinates[1]}
                      longitude={feature.geometry.coordinates[0]}
                      closeButton={false}
                    >
                      <span style={{ color: "black", fontSize: "14px" }}>
                        {feature.properties.name}
                      </span>
                    </Popup>
                  ))}
                </>
              )}
            </ReactMapGL>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 5,
            borderLeft: border,
            padding: "4px",
            maxHeight: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxHeight: "50%",
              overflow: "hidden",
              padding: "2px 0",
            }}
          >
            <Label>Oracle Requests</Label>
            <TableContainer maxHeight={"100%"}>
              <Table
                style={{
                  border,
                  maxHeight: "100%",
                }}
                stickyHeader
              >
                <TableHead>
                  <TableRow
                    style={{
                      backgroundColor: accentColor,
                    }}
                  >
                    <HeadCell>Block</HeadCell>
                    <HeadCell>Requester</HeadCell>
                    <HeadCell>IMO</HeadCell>
                    <HeadCell>Timestamp</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ overflowY: "scroll" }}>
                  {_.orderBy(requests, "returnValues.timestamp", "desc").map(
                    (request, idx) => (
                      <TableRow key={idx}>
                        <Cell>{request.blockNumber}</Cell>
                        <Cell>{request.returnValues.requester}</Cell>
                        <Cell>{request.returnValues.imo}</Cell>
                        <Cell>
                          <TimeAgo
                            style={{ color: "white" }}
                            date={
                              new Date(request.returnValues.timestamp * 1000)
                            }
                          ></TimeAgo>
                        </Cell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxHeight: "50%",
              overflow: "hidden",
              padding: "2px 0",
            }}
          >
            <Label>Logged Data</Label>
            <TableContainer maxHeight={"100%"}>
              <Table
                style={{
                  border,
                  maxHeight: "100%",
                }}
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <HeadCell>Block</HeadCell>
                    <HeadCell>IMO</HeadCell>
                    <HeadCell>Position</HeadCell>
                    <HeadCell>Timstsamp</HeadCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.orderBy(positions, "returnValues.timestamp", "desc").map(
                    (position, idx) => (
                      <TableRow key={idx}>
                        <Cell>{position.blockNumber}</Cell>
                        <Cell>{position.returnValues.imo}</Cell>
                        <Cell>
                          ({position.returnValues.lat / 1000000},{" "}
                          {position.returnValues.lon / 1000000})
                        </Cell>
                        <Cell>
                          {new Date(
                            position.returnValues.timestamp * 1000
                          ).toISOString()}
                        </Cell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}

const Cell = ({ children }) => {
  return (
    <TableCell style={{ color: "white", padding: "8px" }} align={"left"}>
      {children}
    </TableCell>
  );
};

const HeadCell = ({ children }) => {
  return (
    <TableCell
      style={{ color: "white", padding: "8px", backgroundColor: accentColor }}
      align={"left"}
    >
      {children}
    </TableCell>
  );
};

const Label = ({ children }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
      }}
    >
      <span
        style={{
          borderRadius: "4px",
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
          height: "30px",
          padding: "8px",
          backgroundColor: accentColor,
          display: "flex",
          alignItems: "center",
          fontSize: "1em",
          fontWeight: 300,
        }}
      >
        <i>{children}</i>
      </span>
    </div>
  );
};

export default App;
